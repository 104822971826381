// Editddress.js
import React, { useState, useCallback } from "react";

// Phone number
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";

// Animations
import { css } from "@emotion/react";
import { SyncLoader } from "react-spinners";

// Email validation
import * as EmailValidator from "email-validator";

// Country and region
import CountryRegionSelector from "../../Components/CountryRegionSelector/CountryRegionSelector.js";

// OpenStreetMap
import OpenStreetMapComponent from "../../Components/OpenStreetViewComponent.js";

// Imageupload
import ImageUpload from "../../Components/ImageUpload.js";

// Image resize
import { resizeImage } from "../../utils/resizeImage.js";
import { IMAGE_MAX_FILE_SIZE, IMAGE_MAX_PIXEL_SIZE } from "../../config.js";

// API
import { APIwithAuth } from "../../AxiosAPI/AxiosAPI.js";

const EditAddress = ({
  address,
  userPhone,
  userEmail,
  userUsedHashes,
  onClose,
  token,
  onUnAuthorized,
}) => {
  // Menu 1
  const [companyLocation] = useState(address.location_company ? true : false);
  const [companyName, setCompanyName] = useState(address.location_company);
  const [fiscalID, setFiscalID] = useState(address.location_fiscal_id);
  const [contactName, setContactName] = useState(address.location_contact_name);
  const [originalContactName] = useState(address.location_contact_name);
  const [phone, setPhone] = useState(address.location_phone);
  const [originalPhone] = useState(address.location_phone);
  const [email, setEmail] = useState(address.location_email);
  const [originalEmail] = useState(address.location_email);

  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isFiscalIDValid, setIsFiscalIDValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true); // Already filled
  const [isPhoneValid, setIsPhoneValid] = useState(true); // Already filled
  const [isEmailValid, setIsEmailValid] = useState(true); // Already filled
  const [isCompanyValid, setIsCompanyValid] = useState(true); // Company valid means name and fiscal number
  const [isMenu1Valid, setIsMenu1Valid] = useState(true);

  // Menu 2
  const [designation, setDesignation] = useState(address.address_designation);
  const [originalDesignation] = useState(address.address_designation);
  const [isPublic, setIsPublic] = useState(
    address.address_hashcode ? false : true
  );
  const [showMenu2SecondQuestion, setShowMenu2SecondQuestion] = useState(true);
  const [hashCode, setHashCode] = useState(address.address_hashcode);
  const [originalHashCode] = useState(address.address_hashcode);
  const [hashErrorMessage, setHashErrorMessage] = useState("");

  const [isDesignationValid, setIsDesignationValid] = useState(true);
  const [isHashCodeValid, setIsHashCodeValid] = useState(true);
  const [isMenu2Valid, setIsMenu2Valid] = useState(true);

  // Menu 3
  const [country, setCountry] = useState(address.country_acronym);
  const [postalCode, setPostalCode] = useState(address.location_postal_code);
  const [originalPostalCode] = useState(address.location_postal_code);
  const [city, setCity] = useState(address.location_city);
  const [region, setRegion] = useState(address.location_region);
  const [addressLines, setAddressLines] = useState(
    address.location_address_lines
  );
  const [originalAddressLines] = useState(address.location_address_lines);

  const [isCityValid, setIsCityValid] = useState(true);
  const [isAddressLinesValid, setIsAddressLinesValid] = useState(true);
  const [isMenu3Valid, setIsMenu3Valid] = useState(true);

  // Menu 4
  const [coordinates, setCoordinates] = useState({
    lat: address.location_coordinates_latitude,
    lng: address.location_coordinates_longitude,
  });
  const [originalCoordinates] = useState({
    lat: address.location_coordinates_latitude,
    lng: address.location_coordinates_longitude,
  });
  const [notes, setNotes] = useState(address.location_notes);
  const [originalNotes] = useState(address.location_notes);
  const [loadingImages, setLoadingImages] = useState(false);
  const [savingOnMenu4, setSavingOnMenu4] = useState(false);

  // Menu 5
  const [initialImages, setInitialImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // General
  const [menuNumber, setMenuNumber] = useState(1);
  const usedHashes = useState(userUsedHashes);
  // Memoize the onUnAuthorized function
  const onUnAuthorizedCallback = useCallback(onUnAuthorized, [onUnAuthorized]);
  const [savingOnMenu5, setSavingOnMenu5] = useState(false);
  const animationCSS = css`
    display: block;
    margin: 0 auto;
  `;

  // ********************************************************************
  // Menu 1

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    const isValid = value.length >= 3 && value.length <= 100;
    setIsCompanyNameValid(isValid); // Validate input length

    // Update company information validity
    if (
      companyLocation &&
      isValid &&
      isFiscalIDValid &&
      isNameValid &&
      isPhoneValid &&
      isEmailValid
    ) {
      setIsCompanyValid(isValid);
    } else setIsCompanyValid(false);

    // Update menu 1 validity
  };

  const handleFiscalIDChange = (e) => {
    const value = e.target.value;
    setFiscalID(value);
    const isValid = value.length >= 3 && value.length <= 50;
    setIsFiscalIDValid(isValid); // Validate input length

    // Update company information validity
    if (companyLocation && isValid && isCompanyNameValid) {
      setIsCompanyValid(isValid);
    } else setIsCompanyValid(false);

    // Update menu 1 validity
    if (
      companyLocation &&
      isCompanyNameValid &&
      isValid &&
      isNameValid &&
      isPhoneValid &&
      isEmailValid
    ) {
      setIsMenu1Valid(isValid);
    } else setIsMenu1Valid(false);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setContactName(value);
    const isValid = value.length >= 3 && value.length <= 100;
    setIsNameValid(isValid); // Validate input length

    // Update menu 1 validity
    if (
      (companyLocation &&
        isCompanyNameValid &&
        isFiscalIDValid &&
        isValid &&
        isPhoneValid &&
        isEmailValid) ||
      (!companyLocation && isValid && isPhoneValid && isEmailValid)
    ) {
      setIsMenu1Valid(isValid);
    } else setIsMenu1Valid(false);
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);

    const phoneNumber = parsePhoneNumber(formattedValue);

    const isValid = phoneNumber && phoneNumber.isValid();
    setIsPhoneValid(isValid);

    // Update menu 1 validity
    if (
      (companyLocation &&
        isCompanyNameValid &&
        isFiscalIDValid &&
        isNameValid &&
        isValid &&
        isEmailValid) ||
      (!companyLocation && isNameValid && isValid && isEmailValid)
    ) {
      setIsMenu1Valid(true);
    } else setIsMenu1Valid(false);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const isValid = EmailValidator.validate(value);
    setIsEmailValid(isValid);

    // Update menu 1 validity
    if (
      (companyLocation &&
        isCompanyNameValid &&
        isFiscalIDValid &&
        isNameValid &&
        isPhoneValid &&
        isValid) ||
      (!companyLocation && isNameValid && isPhoneValid && isValid)
    ) {
      setIsMenu1Valid(isValid);
    } else setIsMenu1Valid(false);
  };

  const handleMenu1PressNext = () => {
    // Logic to determine next question or action based on current state
    if (companyLocation) {
      console.log("Company Name:", companyName);
      console.log("FiscalID:", fiscalID);
      // Implement logic to proceed to next step or submit form
    } else {
      console.log("Person Name:", contactName);
      // Implement logic to proceed to next step or submit form
    }
    setMenuNumber(2);
  };

  // ********************************************************************
  // Menu 2

  const handleDesignationChange = (e) => {
    const value = e.target.value;
    setDesignation(value);
    const isValid = value.length >= 4 && value.length <= 100;
    setIsDesignationValid(isValid); // Validate input length

    if (isValid && (isPublic || (!isPublic && isHashCodeValid))) {
      setIsMenu2Valid(true);
    } else setIsMenu2Valid(false);
  };

  const handleHashCodeChange = (e) => {
    const value = e.target.value;
    setHashCode(value);

    if (usedHashes[0].includes(value) && value !== originalHashCode)
      // allow the original to be used
      setHashErrorMessage("Key already in use");
    else setHashErrorMessage("1 to 5 characters");

    const isValid =
      value.length >= 1 &&
      value.length <= 50 &&
      (!usedHashes[0].includes(value) || value === originalHashCode);
    setIsHashCodeValid(isValid); // Validate input length

    if (isDesignationValid && (isPublic || (!isPublic && isValid))) {
      setIsMenu2Valid(true);
    } else setIsMenu2Valid(false);
  };

  const handleResponseToIsPublicQuestion = (response) => {
    setIsPublic(response === true);
    setShowMenu2SecondQuestion(true);

    // Update menu 2 validity
    if (response === true && isDesignationValid) {
      setIsMenu2Valid(true);
    } else if (response === false && isDesignationValid && isHashCodeValid) {
      setIsMenu2Valid(true);
    } else setIsMenu2Valid(false);
  };

  const handleMenu2PressNext = () => {
    setMenuNumber(3);
  };

  const handleMenu2PressBack = () => {
    // Logic to determine next question or action based on current state
    console.log("Designation:", designation);
    console.log("hashcode:", hashCode);
    setMenuNumber(1);
  };

  // ********************************************************************
  // Menu 3

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handleRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCity(value);
    const isValid = value.length >= 2 && value.length <= 100;
    setIsCityValid(isValid); // Validate input length

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    setPostalCode(value);
  };

  const handleAddressLinesChange = (e) => {
    const value = e.target.value;
    setAddressLines(value);
    const isValid = value.length >= 5 && value.length <= 100;
    setIsAddressLinesValid(isValid); // Validate input length

    // Update menu 3 validity
    if (country && region && isCityValid && isAddressLinesValid) {
      setIsMenu3Valid(true);
    } else setIsMenu3Valid(false);
  };

  const handleMenu3PressNext = async () => {
    setMenuNumber(4);
  };

  const handleMenu3PressBack = () => {
    setMenuNumber(2);
  };

  // ********************************************************************
  // Menu 4

  const updateCoordinates = (newCoordinates) => {
    setCoordinates(newCoordinates);
    //console.log("Coordinates on add address: ", newCoordinates);
  };

  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNotes(value);
  };

  const fetchImagesForAddress = async (idsArray) => {
    const APIInstance = APIwithAuth(token);

    const fetchImage = async (id) => {
      try {
        const response = await APIInstance.get(`api/user/images/${id}`);
        //console.log (response);

        const base64Image = response.data.image; // Get the base64 string

        return {
          name: `existing-image-${id}`,
          preview: base64Image,
          file: base64Image, // Keep the base64 string as file data
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          onUnAuthorizedCallback();
        }
        return [];
      }
    };

    const imagePromises = idsArray.map((id) => fetchImage(id));
    return Promise.all(imagePromises);
  };

  const handleMenu4PressNext = async () => {
    if (address.images_ids && initialImages.length === 0) {
      // Fetch images if they exist and not already uploaded
      setLoadingImages(true);
      try {
        console.log("Image_ids: ", address.images_ids);
        const idsArray = address.images_ids.split(",").map((id) => id.trim());

        const images = await fetchImagesForAddress(idsArray);

        setInitialImages(images);
        console.log("Images:", images);
        setMenuNumber(5);
      } catch (error) {
        console.error("Failed to fetch images:", error);
      } finally {
        setLoadingImages(false);
      }
    } else {
      console.log("address.images_ids:", address.images_ids);
      console.log("initialImages:", initialImages.length);
      setMenuNumber(5);
    }
  };

  // It is better to check if those elements were changed so that no unnecessary API calls are made
  function isNecessaryToUpdateLocation() {
    return (
      originalContactName !== contactName ||
      originalPhone !== phone ||
      originalEmail !== email ||
      originalAddressLines !== addressLines ||
      originalPostalCode !== postalCode ||
      originalCoordinates.lat !== coordinates.lat ||
      originalCoordinates.lng !== coordinates.lng ||
      originalNotes !== notes
    );
  }

  function isNecessaryToUpdateAddress() {
    return originalDesignation !== designation || originalHashCode !== hashCode;
  }

  const handleMenu4PressSave = async () => {
    // No images were changed, only the location OR the address content
    console.log("Press save address without changing images");
    // There are 2 database tables: address and location

    // Possible changes for the address
    // - designation
    // - hashcode

    // Possible changes for the location
    // - contact_name
    // - phone
    // - email
    // - address_lines
    // - postal_code
    // - coordinates
    // - notes

    if (!isNecessaryToUpdateLocation() && !isNecessaryToUpdateAddress()) {
      onClose();
      return; // Early exit if no updates are needed
    }

    let locationID = address.location_id;

    if (isNecessaryToUpdateLocation()) {
      console.log("Is necessary to update the location");

      // If a location needs update, it must be created, not updated
      try {
        setSavingOnMenu4(true);

        const result = await createLocationData();
        if (result !== -1) {
          console.log(
            "createLocationData completed successfully, id: ",
            result
          );
          locationID = result;
        } else {
          console.error("createLocationData failed.");
          return; // Exit if creation failed
        }
      } catch (error) {
        console.error("Error in createLocationData: ", error);
        return; // Exit if creation failed
      } finally {
        setSavingOnMenu4(false);
      }
    } else {
      console.log(
        "Is not necessary to update the location, same location will be used: ",
        locationID
      );
    }

    // Now let's proceed with the address using the original locationID or the new one
    try {
      setSavingOnMenu4(true);

      const updateResult = await updateAddressData(locationID);
      if (updateResult) {
        // Address was updated
        onClose();
      } else {
        console.error("updateAddressData failed.");
      }
    } catch (error) {
      console.error("Error in updateAddressData: ", error);
    } finally {
      setSavingOnMenu4(false);
    }
  };

  const handleMenu4PressBack = () => {
    setMenuNumber(3);
  };

  // ********************************************************************
  // Menu 5

  // Submit button to save/update the address that includes also the images
  // This will call the same process as saving the address without images
  // then also handles the images.
  const handleMenu5Save = async () => {
    console.log("Press save address with possible images change");
    // There are 3 database tables: address, location and images

    // Possible changes for the address
    // - designation
    // - hashcode

    // Possible changes for the location
    // - contact_name
    // - phone
    // - email
    // - address_lines
    // - postal_code
    // - coordinates
    // - notes

    // Possible changes for the images
    let locationID = address.location_id; // Holds the new or old ID depending on the needed changes
    let addressID = address.id; // Holds the new or old ID depending on the needed changes

    if (isNecessaryToUpdateLocation()) {
      console.log("Is necessary to update the location");

      // If a location needs update, it must be created, not updated
      try {
        setSavingOnMenu5(true);

        const result = await createLocationData();
        if (result !== -1) {
          console.log(
            "createLocationData completed successfully, id: ",
            result
          );
          locationID = result;
        } else {
          console.error("createLocationData failed.");
          return; // Exit if creation failed
        }
      } catch (error) {
        console.error("Error in createLocationData: ", error);
        return; // Exit if creation failed
      } finally {
        setSavingOnMenu5(false);
      }
    } else {
      console.log(
        "Is not necessary to update the location, same location will be used: ",
        locationID
      );
    }

    // Now let's proceed with the address using the original locationID or the new one
    if (isNecessaryToUpdateLocation() || isNecessaryToUpdateAddress()) {
      try {
        setSavingOnMenu5(true);

        const updateResult = await updateAddressData(locationID);
        if (updateResult) {
          console.log("Address was updated, resulting id: ", updateResult);
          addressID = updateResult;
        } else {
          console.error("updateAddressData failed.");
        }
      } catch (error) {
        console.error("Error in updateAddressData: ", error);
      } finally {
        setSavingOnMenu5(false);
      }
    }

    // Handle images change
    // Preload images contain the 'existing-image' on its name
    const allImagesHaveExistingPrefix = selectedFiles.every((image) =>
      image.name.startsWith("existing-image")
    );

    if (
      !allImagesHaveExistingPrefix ||
      initialImages.length !== selectedFiles.length
    ) {
      console.log("One or more images where changed");
      //console.log (selectedFiles);

      try {
        setSavingOnMenu5(true);
        const { base64Images, fileNames } = await createNewImagesObjects(); // Wait for the images to be converted to base64 strings

        //console.log (base64Images);
        //console.log (fileNames);

        const updateAddressImagesResponse = await updateAddressImages(
          addressID,
          base64Images,
          fileNames
        );

        console.log(
          "Response updateAddressImagesResponse: ",
          updateAddressImagesResponse
        );
      } catch (error) {
        console.error("Error updating the addresses images: ", error);
      }
    } else {
      console.log(
        "No change on images, nothing to change, initialImages: ",
        initialImages.length,
        " selectedFiles:",
        selectedFiles.length
      );
    }

    onClose(); // Exit the edit address
  };

  const handleMenu5PressBack = () => {
    setMenuNumber(4);
  };

  const handleImagesUpdate = (images) => {
    // Handle the uploaded images
    setSelectedFiles(images);
    console.log("Uploaded images / selectedFiles:", images);
  };

  // ********************************************************************
  // API calls









  const updateAddressImages = async (addressID, base64Images, fileNames) => {
    const apiInstance = APIwithAuth(token);

    try {
      const formatedData = {
        address_id: addressID,
        image: base64Images,
        filename: fileNames,
      };

      console.log("Data to be sent to create the address: ", formatedData);

      const response = await apiInstance.post(
        `/api/user/addresses/${addressID}/updateimages`,
        formatedData
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error on updateAddressImages");
      }

      return response.data;
    } catch (error) {
      // Handle different types of errors
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        // console.log ("error.response: ", error.response);
        // console.log ("response.data: ", error.response.data);
        // console.log ("error.response.data.error: ", error.response.data.error);
        // console.log ("error.response.data.messages.sqlState: ", error.response.data.messages.sqlState);

        // If it's a different error, check if it's an API error with details
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages
        ) {
          const sqlState = error.response.data.messages.sqlState;

          // Check if the error is due to a duplicate entry
          if (sqlState === "45000") {
            console.error(
              "45000: Duplicate address entry set on address table trigger"
            );
          } else {
            // Handle other API errors
            console.error("API Error:", error);
          }
        } else {
          // Handle other errors
          console.error("Error creating the address:", error);
          // Rethrow the error to propagate it further if needed
          throw error;
        }
      }
    }
  };






















  const createLocation = async () => {
    const apiInstance = APIwithAuth(token);
    try {
      const formatedData = {
        country_id: 0,
        country_acronym: country,
        contact_name: contactName,
        phone: "+" + phone,
        email: email,
        company: companyName,
        fiscal_id: fiscalID,
        address_lines: addressLines,
        postal_code: postalCode,
        city: city,
        region: region,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        notes: notes,
      };

      //console.log("Data to be sent to create the location: ", formatedData, coordinates);

      const response = await apiInstance.post(
        "/api/user/locations",
        formatedData
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages
        ) {
          console.log(
            "error.response.data.messages: ",
            error.response.data.messages
          );
        } else {
          // Handle other errors
          console.error("Error creating the location:", error);
          // Rethrow the error to propagate it further if needed
          throw error;
        }
      }
    }
  };

  // Creates the location in the database and returns the id of the location
  // If error returns -1
  const createLocationData = async () => {
    try {
      const createLocationResponse = await createLocation();

      console.log("Response createLocationResponse: ", createLocationResponse);

      if (
        createLocationResponse.status === 200 ||
        createLocationResponse.status === 201
      ) {
        // Return the id of the location
        return createLocationResponse.messages.resourceId;
      }
    } catch (error) {
      console.error("Error creating the location: ", error);
      return -1;
    } finally {
      // Set loading to false when the operation is complete (either success or error)
    }
  };

  // From the list of selected images, only creates those that that are not already on
  // base64 format.
  const createNewImagesObjects = async () => {
    try {
      const fileObjects = await Promise.all(
        selectedFiles.map(async (file) => {
          // Check if the object is already a base64 encoded image
          if (file.preview && file.preview.startsWith("data:image/")) {
            // Return the object as it is since it's already processed
            return {
              filename: file.name,
              image: file.preview,
            };
          }

          let processedFile = file;

          // Check if the file exceeds the maxFileSizeImage or dimensions
          const shouldResize = await new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
              img.src = e.target.result;
            };

            img.onload = () => {
              if (
                file.size > IMAGE_MAX_FILE_SIZE ||
                img.width > IMAGE_MAX_PIXEL_SIZE ||
                img.height > IMAGE_MAX_PIXEL_SIZE
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            };

            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
          });

          // Resize the image if it exceeds the maxFileSizeImage or dimensions
          if (shouldResize) {
            processedFile = await resizeImage(file);
          }

          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result;
              resolve({
                filename: processedFile.name,
                image: base64String,
              });
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(processedFile);
          });
        })
      );

      const base64Images = fileObjects.map((obj) => obj.image);
      const fileNames = fileObjects.map((obj) => obj.filename);

      return { base64Images, fileNames };
    } catch (error) {
      console.error("Error converting files:", error);
      throw error;
    }
  };

  // Updates only the designation or hashcode of the address
  // This creates a new row on the database and sets the updated_at of the previous record
  const updateAddress = async (location_id) => {
    const apiInstance = APIwithAuth(token);

    try {
      const formatedData = {
        user_id: address.user_id, // Needed by the API model
        // Possible data changed
        location_id: location_id,
        designation: designation,
        hashcode: hashCode,
      };

      console.log("Data to be sent to update the address: ", formatedData);

      const response = await apiInstance.post(
        `/api/user/addresses/${address.address_id}/update`,
        formatedData
      );

      console.log("Response for address update: ", response);

      if (response.status !== 200 && response.status !== 201) {
        throw new Error("API Error");
      }

      return response.data;
    } catch (error) {
      // Handle different types of errors
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      } else {
        // console.log ("error.response: ", error.response);
        // console.log ("response.data: ", error.response.data);
        // console.log ("error.response.data.error: ", error.response.data.error);
        // console.log ("error.response.data.messages.sqlState: ", error.response.data.messages.sqlState);

        // If it's a different error, check if it's an API error with details
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages
        ) {
          const sqlState = error.response.data.messages.sqlState;

          // Check if the error is due to a duplicate entry
          if (sqlState === "45000") {
            console.error(
              "45000: Duplicate address entry set on address table trigger"
            );
          } else {
            // Handle other API errors
            console.error("API Error:", error);
          }
        } else {
          // Handle other errors
          console.error("(updateAddress) Error updating the address:", error);
          // Rethrow the error to propagate it further if needed
          throw error;
        }
      }
    }
  };

  const updateAddressData = async (location_id) => {
    try {
      // Set loading to true to indicate that data is being transmitted
      console.log("Will try to update address with location_id:", location_id);
      const updateAddressResponse = await updateAddress(location_id);

      console.log("Response updateAddressResponse: ", updateAddressResponse);

      if (
        updateAddressResponse.status !== 200 &&
        updateAddressResponse.status !== 201
      ) {
        throw new Error("API Error while updating the address");
      }

      if (
        updateAddressResponse &&
        updateAddressResponse.messages &&
        updateAddressResponse.messages.resourceId
      ) {
        return updateAddressResponse.messages.resourceId;
      } else return null;
    } catch (error) {
      console.error("Error updating the address: ", error);
      if (error.response && error.response.status === 401) {
        onUnAuthorizedCallback();
      }

      return false;
    }
  };

  //
  //
  //
  //
  // ********************************************************************
  // Render
  // ********************************************************************

  return (
    <div
      name="EditAddress" // Home page container
      style={{
        textAlign: "center",
        alignItems: "center",
      }}
    >
      {menuNumber === 1 && (
        <div name="menu1" className="centralForm">
          {/* Question: The address refers to a company location */}
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>Edit</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>1/5</span>{" "}
          </h1>
          <div>
            <p>
              Previous saved information is prefilled. For a significative
              change - or to change elements that are not possible on this menu,
              is recommend that you delete this address and create a new one.
            </p>
          </div>

          {/* Depending on the answer, show appropriate input */}
          {companyLocation && (
            <div>
              <div className="inputGroup">
                {/* Input field: What is the company name? */}
                <div className="inputLabel">
                  Company/business/institution name
                </div>
                <input
                  readOnly="readOnly"
                  className="textInputReadOnly"
                  type="text"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                  autoComplete="false"
                />
                {!isCompanyNameValid && companyName.length > 0 && (
                  <div className="validationMessage">3 to 100 characters</div>
                )}
              </div>
              <div className="inputGroup">
                {/* Input field: Fiscal number? */}
                <div className="inputLabel">
                  Fiscal number/vat number/GST/registration number (company
                  identifier)
                </div>
                <input
                  readOnly="readOnly"
                  className="textInputReadOnly"
                  type="text"
                  value={fiscalID}
                  onChange={handleFiscalIDChange}
                  autoComplete="false"
                />
                {!isFiscalIDValid && fiscalID.length > 0 && (
                  <div className="validationMessage">3 to 50 characters</div>
                )}
              </div>

              {/* Company address and after the company data is filled */}
              {isCompanyValid && (
                <div>
                  <div className="inputGroup">
                    {/* Input field: What is the contact name? */}
                    <div className="inputLabel">
                      Address contact name (can be you or another person on the
                      address)
                    </div>
                    <input
                      className="textInput"
                      type="text"
                      value={contactName}
                      onChange={handleNameChange}
                      autoComplete="false"
                    />
                    {!isNameValid && contactName.length > 0 && (
                      <div className="validationMessage">
                        3 to 100 characters
                      </div>
                    )}
                  </div>

                  <div className="inputGroup">
                    {/* Input field: Contact phone number? */}
                    <div className="inputLabel">
                      Address contact phone number
                    </div>
                    <PhoneInput
                      className="addAddressPhoneInput"
                      name="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      countryCodeEditable={false}
                      inputProps={{
                        required: true,
                        autoComplete: "phone",
                      }}
                    />
                    {!isPhoneValid && phone.length > 5 && (
                      <div className="validationMessage">
                        Phone number is not valid
                      </div>
                    )}
                  </div>

                  <div className="inputGroup">
                    {/* Input field: What is the contact email? */}
                    <div className="inputLabel">Address contact email</div>
                    <input
                      className="textInput"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      autoComplete="false"
                    />
                    {!isEmailValid && email.length > 3 && (
                      <div className="validationMessage">
                        Email must be a valid
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {!companyLocation && (
            <div>
              <div className="inputGroup">
                {/* Input field: What is the person name? */}
                <div className="inputLabel">
                  Address contact name (can be you or another person on the
                  address)
                </div>
                <input
                  className="textInput"
                  type="text"
                  value={contactName}
                  onChange={handleNameChange}
                  autoComplete="false"
                />
                {!isNameValid && contactName.length > 0 && (
                  <div className="validationMessage">3 to 100 characters</div>
                )}
              </div>

              <div className="inputGroup">
                {/* Input field: Contact phone number? */}
                <div className="inputLabel">Address contact phone number</div>
                <PhoneInput
                  className="addAddressPhoneInput"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  countryCodeEditable={false}
                  inputProps={{
                    required: true,
                    autoComplete: "phone",
                  }}
                />
                {!isPhoneValid && phone.length > 5 && (
                  <div className="validationMessage">
                    Phone number is not valid
                  </div>
                )}
              </div>

              <div className="inputGroup">
                {/* Input field: What is the contact email? */}
                <div className="inputLabel">Address contact email</div>
                <input
                  className="textInput"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="false"
                />
                {!isEmailValid && email.length > 3 && (
                  <div className="validationMessage">Email must be a valid</div>
                )}
              </div>
            </div>
          )}

          {/* Lower button for next */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              {isMenu1Valid && (
                <button
                  className="unSelectedButton"
                  onClick={handleMenu1PressNext}
                >
                  Next &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>

          {/* Lower button to cancel */}
          <div style={{ marginTop: "40px" }}>
            <div className="twoHorizontalButtons">
              <button className="cancelButton" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {menuNumber === 2 && (
        <div name="menu2" className="centralForm">
          {/* Designation */}
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>Edit</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>2/5</span>{" "}
          </h1>

          <div>
            <h4 style={{ marginTop: "70px", marginBottom: "20px" }}>
              What designation do you want to give to your address?{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Home, Work, Amazon, Uber{" "}
              </span>{" "}
              are good examples
            </h4>
          </div>

          <div className="inputGroup">
            {/* Input field: Address designation */}
            <div className="inputLabel">
              Designation. Keep it simple and accurate as a title should be
            </div>
            <input
              className="textInput"
              type="text"
              value={designation}
              onChange={handleDesignationChange}
              autoComplete="false"
            />
            {!isDesignationValid && designation.length > 0 && (
              <div className="validationMessage">4 to 100 characters</div>
            )}
          </div>

          {isDesignationValid && (
            <div>
              <div>
                <h4 style={{ marginTop: "70px", marginBottom: "5px" }}>
                  Do you want to make this address{" "}
                  <span style={{ fontWeight: "bold" }}>public</span>?
                </h4>
                <h6 style={{ marginTop: "0px", marginBottom: "20px" }}>
                  If set to public, anyone can see it when searching on our
                  platform just using your phone number. If non-public, to
                  obtain your address information, a key is required. This
                  means that you will have control over your address visibility.
                  You can only have one public address at a time.
                </h6>
              </div>

              <div className="twoHorizontalButtonsLarge">
                <button
                  className="unSelectedButtonLarge"
                  onClick={() => handleResponseToIsPublicQuestion(true)}
                  style={{
                    backgroundColor:
                      usedHashes[0].includes("") && originalHashCode !== ""
                        ? "#FFC1AD"
                        : isPublic === false
                        ? "#e3edf4"
                        : "#78bdee",
                  }}
                  disabled={
                    usedHashes[0].includes("") && originalHashCode !== ""
                  }
                >
                  {usedHashes[0].includes("") && originalHashCode !== ""
                    ? "Yes - public (already in use)"
                    : "Yes - public"}
                </button>
                <button
                  className={`${
                    isPublic === false
                      ? "selectedButtonLarge"
                      : "unSelectedButtonLarge"
                  }`}
                  onClick={() => handleResponseToIsPublicQuestion(false)}
                >
                  No - key will be needed
                </button>
              </div>

              {/* Depending on the answer, show appropriate input */}
              {!isPublic && showMenu2SecondQuestion && (
                <div>
                  <div className="inputGroup">
                    {/* Input field: What is the key to be used? */}
                    <div className="inputLabel">
                      Key to be associated with this address. Without
                      it, no one can see this address
                    </div>
                    <input
                      className="textInput"
                      type="text"
                      value={hashCode}
                      onChange={handleHashCodeChange}
                      autoComplete="false"
                    />
                    {!isHashCodeValid > 0 && (
                      <div className="validationMessage">
                        {hashErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Lower buttons to go forward, back or cancel on menu 2*/}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                className="unSelectedButton"
                onClick={handleMenu2PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>
              {isMenu2Valid && (
                <button
                  className="unSelectedButton"
                  onClick={handleMenu2PressNext}
                >
                  Next &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              <button className="cancelButton" onClick={onClose}>
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menu 3 - Address details */}
      {menuNumber === 3 && (
        <div name="menu3" className="centralForm">
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>Edit</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>3/5</span>{" "}
          </h1>

          <div>
            <h4 style={{ marginTop: "70px", marginBottom: "20px" }}>
              Lets provide more details to make it accurate
            </h4>
          </div>

          {/* Country and region selector */}
          <div>
            <CountryRegionSelector
              disabled={true}
              selectedCountry={country}
              setSelectedCountry={handleCountryChange}
              selectedRegion={region}
              setSelectedRegion={handleRegionChange}
            />
          </div>

          {/* Show city and postal code after region is filled */}
          {region && (
            <div>
              {/* 2 columns, for city and postal code */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="inputGroup"
                  style={{ width: "45%", marginTop: "10px" }}
                >
                  {/* Input field: city */}
                  <div className="inputLabel">City</div>
                  <input
                    readOnly="readOnly"
                    className="textInputReadOnly"
                    type="text"
                    value={city}
                    onChange={handleCityChange}
                    autoComplete="false"
                  />
                  {!isCityValid && city.length > 0 && (
                    <div className="validationMessage">2 to 50 characters</div>
                  )}
                </div>

                <div
                  className="inputGroup"
                  style={{ width: "45%", marginTop: "10px" }}
                >
                  {/* Input field: postal code */}
                  <div className="inputLabel">Postal code</div>
                  <input
                    className="textInput"
                    type="text"
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    autoComplete="false"
                  />
                </div>
              </div>

              {/* Show address lines after city is filled */}
              {isCityValid && (
                <div className="inputGroup">
                  {/* Input field: addressLines */}
                  <div className="inputLabel">
                    Address details (street, number or your address as you know
                    it will be understood by others...)
                  </div>
                  <input
                    className="textInput"
                    type="text"
                    value={addressLines}
                    onChange={handleAddressLinesChange}
                    autoComplete="false"
                  />
                  {!isAddressLinesValid && addressLines.length > 0 && (
                    <div className="validationMessage">5 to 100 characters</div>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Lower buttons to go forward, back or cancel on menu 3 */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                className="unSelectedButton"
                onClick={handleMenu3PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>
              {isMenu3Valid && (
                <button
                  className="unSelectedButton"
                  onClick={handleMenu3PressNext}
                >
                  Next &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              <button className="cancelButton" onClick={onClose}>
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menu 4 - Open street view*/}
      {menuNumber === 4 && (
        <div name="menu4" style={{ width: "min(93vw, 1200px)" }}>
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>Edit</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>4/5</span>{" "}
          </h1>

          <div>
            <h5 style={{ marginTop: "70px", marginBottom: "20px" }}>
              Increase the precision of your address locating a precise point on
              the map. It can be later used to navigate until your location.
            </h5>
          </div>

          <OpenStreetMapComponent
            initialCoordinates={coordinates}
            onUpdateCoordinates={updateCoordinates}
          />

          <div
            className="inputGroup"
            style={{ width: "100%", marginTop: "10px" }}
          >
            {/* Input field: notes */}
            <div className="inputLabel">
              Optionally write some notes that might help other to reach your
              address
            </div>
            <input
              className="textInput"
              type="text"
              value={notes}
              placeholder="Turn left near the big tree, and leave the package on the blue door, not on the red door."
              onChange={handleNotesChange}
              autoComplete="false"
            />
          </div>

          {/* Lower buttons to go forward, back or cancel on menu 4 */}
          <div style={{ marginTop: "60px" }}>
            <div className="threeHorizontalButtons">
              <button
                disabled={loadingImages || savingOnMenu4}
                className="unSelectedButton"
                style={{
                  width: "200px",
                }}
                onClick={handleMenu4PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>

              <button
                disabled={loadingImages}
                className="unSelectedButton"
                style={{
                  backgroundColor: "#BBFFB7",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "200px",
                }}
                onClick={handleMenu4PressSave}
              >
                <span>Save and exit</span>

                {savingOnMenu4 && (
                  <SyncLoader
                    color={"#4463FF"}
                    loading={savingOnMenu4}
                    size={6}
                    css={{ marginTop: "0px" }}
                  />
                )}
                {!savingOnMenu4 && (
                  <span style={{ fontSize: "smaller" }}>
                    without changing images
                  </span>
                )}
              </button>

              <button
                disabled={savingOnMenu4}
                className="unSelectedButton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "200px",
                }}
                onClick={handleMenu4PressNext}
              >
                <span>
                  Edit images &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
                {loadingImages && (
                  <SyncLoader
                    color={"#4463FF"}
                    loading={loadingImages}
                    size={6}
                    css={{ marginTop: "0px" }}
                  />
                )}
                {!loadingImages && (
                  <span style={{ fontSize: "smaller" }}>
                    image loading is needed
                  </span>
                )}
              </button>
            </div>
            <div style={{ marginTop: "40px" }}>
              <button className="cancelButton" onClick={onClose}>
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menu 5 - Images and submit the address */}
      {menuNumber === 5 && (
        <div name="menu5" className="centralForm">
          <h1 style={{ color: "darkslateblue" }}>
            <span style={{ fontWeight: "bold" }}>Edit</span> address -{" "}
            <span style={{ fontWeight: "bold" }}>5/5</span>{" "}
          </h1>

          <div>
            <h5 style={{ marginTop: "70px", marginBottom: "20px" }}>
              You can also add imagens that might help to locate your address.
            </h5>
          </div>

          <div>
            <ImageUpload
              initialFiles={initialImages}
              onFilesChange={handleImagesUpdate}
            />
          </div>

          {/* Lower buttons to finish, back or cancel on menu 5 */}
          <div style={{ marginTop: "60px" }}>
            <div className="twoHorizontalButtons">
              <button
                disabled={savingOnMenu5}
                className="unSelectedButton"
                onClick={handleMenu5PressBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
                &nbsp; Back
              </button>

              <button
                className="unSelectedButton"
                style={{ backgroundColor: "#BBFFB7" }}
                onClick={handleMenu5Save}
              >
                Save &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-right-short"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </button>
            </div>
            {!savingOnMenu5 && (
              <div style={{ marginTop: "40px" }}>
                <button className="cancelButton" onClick={onClose}>
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            )}
            {savingOnMenu5 && (
              <div style={{ marginTop: "40px" }}>
                <SyncLoader
                  color={"#4463FF"}
                  loading={savingOnMenu5}
                  css={animationCSS}
                  size={15}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAddress;
