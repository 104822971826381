import React, { useState, useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";

// Icons
import { MDBIcon } from "mdb-react-ui-kit";

// Animations
import { SyncLoader } from "react-spinners";

// Phone number
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phoneCleaner, validatePhoneNumber } from "../utils/phone";

// API
import { APIwithoutAuth } from "../AxiosAPI/AxiosAPI";

// Address details view
import AddressDetails from "../Views/AddressDetails";

// Address details view
import ImageDetails from "../Views/ImageDetails";

const HomePage = ({ userCountry, initialPhoneValue, initialHashValue }) =>
{

  // State to store the phone and hash values
  const [phone, setPhone] = useState("");
  const [hash, setHash] = useState("");

  const [initialPhone, setInitialPhone] = useState(initialPhoneValue);
  const [initialHash, setInitialHash] = useState(initialHashValue);

  const [phoneForInputForm, setPhoneForInputForm] = useState("");

  const [statusMessage, setstatusMessage] = useState("");
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [addressData, setAddressData] = useState("");

  // To remove address information if searching for another
  const [loadingAddress, setLoadingAddress] = useState(false);


  // **********************************************
  // Validations before sending the data to the API
  // **********************************************
  const validateForm = useCallback(() =>
  {
    const errors = {};

    if (!phone)
    {
      errors.phone = "Phone number is required";
    } else if (phone.length < 8)
    {
      errors.phone = "Phone number must have more than 8 digits";
    } else if (!validatePhoneNumber(phone))
    {
      errors.phone = "Phone number is not valid";
    } else if (hash.length > 50)
    {
      errors.password = "Hash cannot have more than 50 characters";
    }

    return errors;
  }, [phone, hash]);

  // ********************************************
  // Send API request
  const sendToAPIFormData = useCallback(async () =>
  {
    let response;
    try
    {
      const base64Request = btoa(`${phone} ${hash}`);

      let url = `/web/${base64Request}`;
      console.log("(requested) URL:", url);

      response = await APIwithoutAuth.get(url);

      if (response.status !== 200 && response.status !== 201)
      {
        throw new Error("API Error");
      }
    } catch (error)
    {
      console.error("Error trying to login:", error);
    } finally
    {
      // Set loading to false when the operation is complete (either success or error)
      return response.data;
    }
  }, [phone, hash]);

  // ***********************************
  // Event handler for the search button
  const handleSearch = useCallback(
    async (event) =>
    {
      if (!phone) return;

      if (event)
      {
        event.preventDefault();
      }

      setLoadingAddress(true);
      setstatusMessage(""); // Clear message

      // Use the values of phone and hash for API search
      console.log("(handleSearch) Phone:", phone);
      console.log("(handleSearch) Hash:", hash);

      const errors = validateForm();

      if (Object.keys(errors).length === 0)
      {
        try
        {
          // Make an API call to validate the form data
          const response = await sendToAPIFormData();

          console.log("Response: ", response);
          // Consume the initial values
          setInitialPhone("");
          setInitialHash("");

          if (response.status === 200)
          {
            // Remove the errors
            setNotFoundMessage("");
            setstatusMessage("");
            // Show the fetched data to the user
            setAddressData(response.address[0]);
          } else if (response.status === 204)
          {
            // Not found, reply is ok, but empty
            setNotFoundMessage("Address was not found");
            setstatusMessage("");
            setAddressData("");
          } else
          {
            // Display an error message if the form data is not valid
            if (response.message != null)
            {
              setstatusMessage(response.message);
              setNotFoundMessage("");
            }
          }
        } catch (error)
        {
          if (error.isAxiosError)
          {
            // This block will be executed for errors of type AxiosError
            setstatusMessage(`AxiosError: ${error.message}`);
            setNotFoundMessage("");
            console.log("AxiosError:", error.message);
          } else
          {
            console.error("API request error:", error);
            // Handle API error (e.g., show a generic error message)
            setstatusMessage(
              "An error occurred. Please try again later (#423)"
            );
            setNotFoundMessage("");
          }
        } finally
        {
          setLoadingAddress(false);
        }
      } else
      {
        // Form is invalid, handle errors
        setstatusMessage(Object.values(errors)[0]); // Shows the first error. Others might be present.
        setNotFoundMessage("");
        setLoadingAddress(false);
      }
    },
    [phone, hash, sendToAPIFormData, validateForm]
  ); // Include dependencies that the function relies on

  // **************************************************
  // Set state with initialPhone and initialHash values
  useEffect(() =>
  {

    if (initialHash)
    {
      setHash(initialHash);
    }
    if (initialPhone)
    {
      setPhoneForInputForm("+" + initialPhone); // Phone to be displayed in the form
      setPhone(initialPhone); // Phone to be set as the search value for the API
    }


    // Wait for the values to be set before starting the search
    if (initialPhone)
    {
      setTimeout(() =>
      {
        handleSearch();
      }, 0);
    }
  }, [initialPhone, initialHash, handleSearch]);

  //
  //
  //
  //
  //
  //

  return (
    <div
      name="HomePage" // Home page container
      style={{
        flex: "1 0 auto",
        marginTop: "90px",
        overflowy: "auto",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div>
        <img // Background image
          style={{
            height: "100vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: "-1",
            opacity: addressData ? "0" : "1",
          }}
          src="/images/background/landing.jpg"
          alt="Backgroundimage"
        ></img>

        <div>
          <h1 // Search any address text
            style={{
              color: "darkslateblue",
              fontWeight: "bold",
              fontFamily: "Roboto",
            }}
          >
            Search any address
          </h1>

          <div>
            <Form>
              <div className="phone-hash-search-container">
                {/* Phone input */}
                <div>
                  <PhoneInput
                    className="signupPhoneInput"
                    country={userCountry}
                    name="phone"
                    value={phoneForInputForm}
                    onChange={(value, country, event, formattedValue) =>
                    {
                      console.log(
                        "Phone onchange:",
                        value,
                        country,
                        event,
                        formattedValue
                      );
                      setPhone(phoneCleaner(formattedValue));
                    }}
                    countryCodeEditable={false}
                    inputProps={{
                      required: true,
                      autoComplete: "phone",
                    }}
                  />
                </div>

                {/* Hash input */}
                <Form.Control className="hashInput"
                  type="text"
                  placeholder="key"
                  name="hash"
                  value={hash}
                  onKeyDown={(e) =>
                  {
                    const invalidChars = [" ", "|", "&", "/"];
                    if (invalidChars.includes(e.key))
                    {
                      e.preventDefault();
                    } else if (e.key === "Enter")
                    {
                      e.preventDefault();
                      handleSearch(e);
                    } else if (/^[A-Z]$/.test(e.key))
                    {
                      // If a capital letter key is pressed
                      e.preventDefault();
                      const lowercaseChar = e.key.toLowerCase();
                      const start = e.target.selectionStart;
                      const end = e.target.selectionEnd;
                      const value = e.target.value;
                      e.target.value =
                        value.slice(0, start) +
                        lowercaseChar +
                        value.slice(end);
                      e.target.setSelectionRange(start + 1, start + 1); // Move the cursor
                      setHash(e.target.value); // Update the state
                    }
                  }}
                  onPaste={(e) =>
                  {
                    e.preventDefault();
                    const pastedText = (
                      e.clipboardData || window.clipboardData
                    ).getData("text");
                    const sanitizedText = pastedText
                      .replace(/[ |&/]/g, "") // Spaces, |, &, / not allowed
                      .toLowerCase();
                    e.target.value = sanitizedText;
                    setHash(sanitizedText);
                  }}
                  onChange={(e) =>
                  {
                    const sanitizedText = e.target.value
                      .replace(/[ |&/]/g, "") // Spaces, |, & not allowed
                      .toLowerCase();
                    setHash(sanitizedText);
                  }}
                />

                <button className="searchButton" onClick={(e) => handleSearch(e)}>
                  <MDBIcon className="searchButtonIcon" icon="search" />
                </button>
              </div>
            </Form>
          </div>

          {statusMessage && (
            <h2
              style={{
                borderRadius: "10px",
                marginTop: "10px",
                fontFamily: "Roboto",
                fontWeight: "initial",
                border: "1px solid",
                padding: "5px",
                fontSize: "2rem",
              }}
            >
              {statusMessage}
            </h2>
          )}

          {!loadingAddress && notFoundMessage && (
            <div>
              <h2
                style={{
                  borderRadius: "10px",
                  marginTop: "30px",
                  fontFamily: "Roboto",
                  fontWeight: "initial",
                  border: "1px",
                  padding: "5px",
                  fontSize: "3rem",
                }}
              >
                Address was not found
              </h2>
              <h4>
                Or the phone number is not registered or the key is not correct
              </h4>
            </div>
          )}

          {loadingAddress && (
            <SyncLoader
              color="#000000"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            />
          )}

          {/* There is a address to show */}
          {addressData && !loadingAddress && (
            <div
              style={{
                borderRadius: "10px",
                marginTop: "30px",
                fontFamily: "Roboto",
                fontWeight: "initial",
                border: "1px",
                fontSize: "1rem",
                backgroundColor: "#e8f0fe",
                maxWidth: `clamp(200px, 92vw, 800px)`,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <AddressDetails address={addressData} />
            </div>
          )}
          {/* There are images to show? */}
          {addressData && addressData.images_ids && !loadingAddress && (
            <div
              style={{
                borderRadius: "10px",
                marginTop: "30px",
                fontFamily: "Roboto",
                fontWeight: "initial",
                border: "1px",
                fontSize: "1rem",
                backgroundColor: "#e8f0fe",
                maxWidth: `clamp(200px, 80vw, 800px)`,
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
            >
              <ImageDetails images_ids={addressData.images_ids} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
