import React, { useEffect } from 'react';
import './ProductBlock.css';

const WebPageBusiness = () =>
{
  useEffect(() =>
  {
    const hash = window.location.hash;
    if (hash)
    {
      const element = document.querySelector(hash);
      if (element)
      {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div style={{ padding: '20px', marginTop: '80px', textAlign: 'center' }}>

      <div>
        <div className="product-block" id="websites">
          <h2 className="block-title-left">Do you own a webshop or an e-commerce website?</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/Ecommerce_700x400.webp" alt="Welcome" />
            </div>
            <div className="text-column-aligned-left">
              <p>For the store: Easy obtain the address, phone number and company data of your clients</p>
              <p>For clients: Create an address just providing the phone number, no need to fill the boring form</p>
              <p style={{ fontWeight: 'bold', lineHeight: '1' }}>Common problems solved:</p>
              <p>Wrong phone numbers, incomplete addresses</p>
              <p>Delays and costs related to returning packages due to a weak address</p>
              <p>Time lost filling the customer details on your invoicing system and/or on the transport company</p>
              <p>Always obtain your client updated data</p>
            </div>
          </div>
        </div>

        <div className="product-block" id="deliveries" style={{ marginTop: '50px' }}>
          <h2 className="block-title-right">Do you handle deliveries?</h2>
          <div className="block-content">
            <div className="text-column-aligned-right">
              <p>For transport companies avoid losing time and money with incomplete addresses</p>
              <p>Get the GPS location, images and instructions to reach anywhere</p>
              <p>Avoid losing time and money driving around looking for the correct delivery location</p>
              <p>Save time and driving associated costs</p>
            </div>
            <div className="image-column">
              <img src="/images/web/TruckDriver_700x400.webp" alt="Avoid errors" />
            </div>
          </div>
        </div>

        <div className="product-block" id="rental-business" style={{ marginTop: '50px' }}>
          <h2 className="block-title-left">Do you own a rental business?</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/HousingRentHomeOwner_700x400.webp" alt="RemoteLocation" />
            </div>
            <div className="text-column-aligned-left">
              <p>How frequently do your customers contact you to help them reach the location?</p>
              <p>Avoid repeated contacts by providing a correct location packet together with instructions to reach it or to do the self check-in. Your client will be happy.</p>
              <p>Add images that describe the location or the self check-in process</p>
              <p>Change once, and all references to the address will be updated</p>
              <p>Easy and fast check-in means happy customers</p>
            </div>
          </div>
        </div>

        <div className="product-block" id="industries" style={{ marginTop: '50px' }}>
          <h2 className="block-title-right">Or any other business where you deal with addresses?</h2>
          <div className="block-content">
            <div className="text-column-aligned-right">
              <p>Everything that relates to addresses can use our service</p>
              <p>Events locations, businesses, hotels, restaurants,...</p>
              <p>A precise address is the key to being found</p>
            </div>
            <div className="image-column">
              <img src="/images/web/Industries_700x400.webp" alt="Industries" />
            </div>
          </div>
        </div>

        <div className="product-block" id="automatic-filling" style={{ marginTop: '50px' }}>
          <h2 className="block-title-left">Automatic filling without errors</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/ErrorFree_700x400.webp" alt="ErrorFree" />
            </div>
            <div className="text-column-aligned-left">
              <p>Most webshops and e-commerce websites require filling out address details. This can be automatic using your client's fastaddress</p>
              <p>Since the address is still provided by the customer, the address contains a human understandable location, meaning that it is adjusted to the local delivery, resulting in a high rate of delivery success</p>
              <p>Our interface can also provide useful manipulations, such as removing invalid characters not accepted on your system. For example, "Ayşegül" can be transformed into "Aysegul"</p>
            </div>
          </div>
        </div>

        <div className="product-block" id="plug-ins" style={{ marginTop: '50px' }}>
          <h2 className="block-title-right">Plugins available for the most popular platforms</h2>
          <div className="block-content">
            <div className="text-column-aligned-right">
              <p>Including:</p>
              <li>OpenCart</li>
              <li>Shopify</li>
              <li>BigCommerce</li>
              <li>WooCommerce</li>
              <li>Vendure</li>
              <li>Magento</li>
              <li>Squarespace</li>
            </div>
            <div className="image-column">
              <img src="/images/web/PlugIns_700x400.webp" alt="plug-ins" />
            </div>
          </div>
        </div>

      </div>

      <div>
        <h1 id="api" style={{ marginTop: '50px' }}>API</h1>
      </div>

      <div className="product-block" id="ApiIntroduction">
        <h2 className="block-title-left">Introduction to the API</h2>
        <div className="block-content">
          <div className="image-column">
            <img src="/images/web/ApiIntroduction_700x400.webp" alt="ApiIntroduction" />
          </div>
          <div className="text-column-aligned-left">
            <p>The use of the API can be done by:</p>
            <li>Our premade plugins</li>
            <li>Your custom solution or implementation</li>
            <br></br>
            <p>The core capabilities of the API would be:</p>
            <li>Getting the fastaddress of a phone number (with or without a key)</li>
            <li>Creating fastaddresses accounts during the user registration on your platform</li>
          </div>
        </div>
      </div>

      <div className="product-block" id="ApiDeveloper" style={{ marginTop: '50px' }}>
        <h2 className="block-title-right">Compreensive API for the developer</h2>
        <div className="block-content">
          <div className="text-column-aligned-right">
            <p>The API is fully documented and easy to use</p>
            <p>Integrate it with any project that uses addresses</p>
            <p>Boost your application fetching standarized data, without having to write a custom solution</p>
            <p>Addresses quality and realiability with a single API call</p>
          </div>
          <div className="image-column">
            <img src="/images/web/ApiDeveloper_700x400.webp" alt="ApiDeveloper" />
          </div>
        </div>
      </div>

      <div className="product-block" id="ApiFlexibility">
        <h2 className="block-title-left">API flexibility for developers</h2>
        <div className="block-content">
          <div className="image-column">
            <img src="/images/web/ApiFlexibility_700x400.webp" alt="ApiFlexibility" />
          </div>
          <div className="text-column-aligned-left">
            <p>Use our API in any type of project</p>
            <p>Can be used side by side with our premade plugins</p>
            <p>Give your application to use or not use the fastaddress, providing a better user experience</p>
            <p>Obtain data that suits your application</p>
          </div>
        </div>
      </div>

      <div className="product-block" id="ApiAvoidErrors" style={{ marginTop: '50px' }}>
        <h2 className="block-title-right">Erros that can be avoided</h2>
        <div className="block-content">
          <div className="text-column-aligned-right">
            <p>Example of browser auto-fill errors that can be avoided. This is a real world example as the address being used is the headquarters of fastaddress</p>
            <p>On this example, of a popular website, the address although correct, is not accepted and the country field was not even filled</p>
            <p>This provides a bad user experience as this error happens several times on this page</p>
            <p>If the API is used, all the data is obtained only using the user phone number. Avoid such errors and provide the best possible user experience</p>
          </div>
          <div className="image-column">
            <img src="/images/web/ApiAvoidErrors_700x400.webp" alt="ApiAvoidErrors" />
          </div>
        </div>
      </div>


    </div>


  );
};

export default WebPageBusiness;