import React from 'react';

const WebPagePrivacyPolicy = () =>
{
  return (
    <div style={{ padding: '20px', marginTop: '80px', textAlign: 'center' }}>

          <h1>Privacy Policy</h1>
          <h2 style={{ marginTop: '30px' }}>Data Sharing Policy</h2>
          <p>
            We are committed to protecting your privacy and ensuring the security of your personal information. This policy explains how we collect, use, and share your data.
          </p>
          <p>
            The main objective of this service is to share your address with others users or companies, however you can protect your privacy selecting those you want to share with.
          </p>


          <h3 style={{ marginTop: '30px' }}>1. Data We Collect</h3>
          <p>
            When you use our service, we collect the following personal information:
          </p>
          <div>
          <ul style={{ textAlign: 'left', display: 'inline-block' }}>
            <li><strong>Name</strong></li>
            <li><strong>Address</strong></li>
            <li><strong>Phone number</strong></li>
          </ul>
          </div>
          <p>
            GPS location and images are optional.
          </p>

          <h3 style={{ marginTop: '30px' }}>2. How Your Data is Used</h3>
          <p>
            Your data is used to facilitate interactions between users for specific situations. For example:
          </p>
          <ul style={{ textAlign: 'left', display: 'inline-block' }}>
            <li>other users so that others can find a determined location.</li>
            <li>online stores to make the checkout faster and accurate.</li>
            <li>transport companies so that the delivery is accurate.</li>
          </ul>

          <h3 style={{ marginTop: '30px' }}>3. Data Sharing</h3>

          <p>
            Your data will be shared <strong>only</strong> with:
          </p>
          <ul style={{ textAlign: 'left', display: 'inline-block' }}>
            <li>Services where your address was provided - by you</li>
            <li>Anyone that uses fastaddress.net and that have your key for a specific address</li>
            <li>Any 3rd party that uses your details including your key, if you added a key to a address</li>
          </ul>

          <h3 style={{ marginTop: '30px' }}>4. Legal Basis for Sharing</h3>
          <p>
            We share your personal data based on your <strong>explicit consent</strong> provided at the time of registration or data submission.
          </p>

          <h3 style={{ marginTop: '30px' }}>5. Your Rights</h3>
          <p>
            You have the right to:
          </p>
          <ul style={{ textAlign: 'left', display: 'inline-block' }}>
            <li>Access the personal data we have collected about you, using your personal area of fastaddress.net</li>
            <li>Correction or deletion of your data using your personal area of fastaddress.net</li>
            <li>Withdraw your consent for data sharing at any time, just delete a determined address on your personal area of fastaadress.net</li>
          </ul>
          <p>
            To exercise your rights, login using the bottom on the top page.
          </p>

          <h3 style={{ marginTop: '30px' }}>6. Data Security</h3>
          <p>
            We implement technical and organizational measures to protect your personal data, including encryption and secure storage.
          </p>

          <p>
            For more information, please contact us.
          </p>
      </div>
  );
};

export default WebPagePrivacyPolicy;