import React, { useEffect } from 'react';
import './ProductBlock.css';

const WebPagePersonal = () =>
{
  useEffect(() =>
  {
    const hash = window.location.hash;
    if (hash)
    {
      const element = document.querySelector(hash);
      if (element)
      {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div style={{ padding: '20px', marginTop: '80px', textAlign: 'center' }}>

      <div>
        <div className="product-block" id="create-address">
          <h2 className="block-title-left">Create and share your address</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/Welcome_400x400.webp" alt="Welcome" />
            </div>
            <div className="text-column-aligned-left">
              <p>Create your address precisely and share it with others</p>
              <p>Use the same address for all your deliveries</p>
              <p>Use GPS coordinates and optional images to increase the accuracy of your address</p>
              <p>Transport companies can easily find your address details just using your phone number</p>
              <p>Share your address just by sharing your phone number</p>
            </div>
          </div>
        </div>

        <div className="product-block" id="avoid-errors" style={{ marginTop: '50px' }}>
          <h2 className="block-title-right">Avoid errors and save time</h2>
          <div className="block-content">
            <div className="text-column-aligned-right">
              <p>Your address is always updated, no more mistakes</p>
              <p>Address changes? No problem, you can easily update it, in just one place</p>
              <p>Avoid wrongly delivered packages or lost packages</p>
            </div>
            <div className="image-column">
              <img src="/images/web/AvoidErrors_700x400.webp" alt="Avoid errors" />
            </div>
          </div>
        </div>

        <div className="product-block" id="remote-address" style={{ marginTop: '50px' }}>
          <h2 className="block-title-left">Is your address sometimes confusing or remote?</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/RemoteLocation_700x400.webp" alt="RemoteLocation" />
            </div>
            <div className="text-column-aligned-left">
              <p>Works perfectly on remote locations</p>
              <p>Suitable for all types of addresses</p>
              <p>Useful for regions where the standard address system is inadequate</p>
            </div>
          </div>
        </div>

        <div className="product-block" id="sharing-address" style={{ marginTop: '50px' }}>
          <h2 className="block-title-right">Sharing made easy</h2>
          <div className="block-content-right">
            <div className="text-column-aligned-right">
              <p>Sharing your address is easy - just share your phone number</p>
              <p>You can share your personal address or your company</p>
              <p>Available in web browsers and mobile apps</p>
              <p>You can also share your address on your email footers, see below examples (try clicking on them):</p>
              <p>
                <a href="https://fastaddress.net/491600000000" className="text-reset" target="_blank"
                  rel="noopener noreferrer" style={{ fontWeight: 'bold', color: 'blue !important' }}>
                  fastaddress.net/491600000000
                </a></p>
              <p>
                <a href="https://fastaddress.net/491600000000/withkey" className="text-reset" target="_blank"
                  rel="noopener noreferrer" style={{ fontWeight: 'bold', color: 'blue !important' }}>
                  Press here to see my address
                </a></p>
            </div>
            <div className="image-column">
              <img src="/images/web/AddressShare_700x400.webp" alt="Avoid errors" />
            </div>
          </div>
        </div>

        <div className="product-block" id="searching-address" style={{ marginTop: '50px' }}>
          <h2 className="block-title-left">Are you searching for a address?</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/HousingRentInstructions_700x400.webp" alt="Instructions to reach" />
            </div>
            <div className="text-column-aligned-left">
              <p>You were never in difficulties to find a address? Probably you were</p>
              <p>When traveling and reaching an Airbnb, VRBO or booking house, sometimes you need more than just a location. You need details, instructions, and yes, the fastaddress can provide you this information as images and instructions can be added to the address. Useful for homeowners and clients. Addresses made simple</p>
            </div>
          </div>
        </div>

        <div className="product-block" id="address-change" tyle={{ marginTop: '50px' }}>
          <h2 className="block-title-right">Did you change address?</h2>
          <div className="block-content">
            <div className="text-column-aligned-right">
              <p>Maybe you changed your address? Or your address was changed by local authorities?</p>
              <p>No need to retype your address in all your documents, websites, webstores or formularies</p>
              <p>Just change the address in FastAddress and all will be updated</p>
            </div>
            <div className="image-column">
              <img src="/images/web/AddressChange_700x400.webp" alt="Address change" />
            </div>
          </div>
        </div>

        <div className="product-block" id="free" style={{ marginTop: '50px' }}>
          <h2 className="block-title-left">Free</h2>
          <div className="block-content">
            <div className="image-column">
              <img src="/images/web/FreeUse_400x400.webp" alt="Free" />
            </div>
            <div className="text-column-aligned-left">
              <p>Our service is free for sharing personal or company addresses</p>
              <p>No advertising or tracking</p>
              <p>Our objective is to provide a useful service</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default WebPagePersonal;